import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';

import PoliciesNavBar from '../../components/section/policies/PoliciesNavBar';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const ContentArea = styled.div`
  flex: 1 1 auto;
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tile = styled.div`
  flex: 1 1 auto;
  
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
  border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-bottom: 20px;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 calc(50% - 40px);
    margin: 0 20px 80px 20px;
    max-width: 360px;
    box-sizing: border-box;
  }
`;

const TileHead = styled.div`
  padding: 40px 30px 20px 30px;
`;

const TileHeading = styled(H4).attrs({
  uistyle: 'brand160',
  fontWeight: 600,
})`
  padding-bottom: 20px;
`;

const TileText = styled(Text).attrs({
  size: 'xl',
})``;

const TileBody = styled.div`
  padding: 40px 0;
  background-color: ${({ backgroundColor, theme: { colors } }) => colors[backgroundColor]};
  text-align: center;
`;

const FileIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'file-alt'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.info};
  font-size: 60px;
`;

const PadlockIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'lock-alt'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.support};
  font-size: 60px;
`;

const ShieldIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'shield-check'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.success};
  font-size: 60px;
`;

const WarningIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'exclamation-triangle'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.warning};
  font-size: 60px;
`;

const TileFoot = styled.div`
  padding: 20px 30px 40px 30px;
`;

const tiles = [
  {
    title: 'User Terms of Service',
    text: 'Our agreement with a user that joins an existing organisation on Rotaready.',
    icon: (<FileIcon />),
    background: 'infobackground',
    slug: 'user-terms-of-service',
  },
  {
    title: 'Privacy Policy',
    text: 'Our policy regarding what information we collect, how we use it, and what choices you have.',
    icon: (<PadlockIcon />),
    background: 'supportbackground',
    slug: 'privacy-policy',
  },
  {
    title: 'GDPR Data Processing Addendum',
    text: 'Our commitment and compliance with GDPR and the protection of your data.',
    icon: (<ShieldIcon />),
    background: 'successbackground',
    slug: 'data-processing',
  },
  {
    title: 'Acceptable Use Policy',
    text: 'A list of acceptable and unacceptable conduct for our Services.',
    icon: (<WarningIcon />),
    background: 'warningbackground',
    slug: 'acceptable-use-policy',
  },
];

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class PoliciesPage extends React.Component {
  render() {
    return (
      <Body header={header}>
        <SEO
          title="Terms & Policies - Rotaready"
          url="policies"
        />

        <ResponsiveContainer>
          <ContentWrapper>
            <TitleWrapper>
              <H1 uistyle="brand160" text="Terms & Policies" />
            </TitleWrapper>

            <BodyWrapper>
              <PoliciesNavBar />

              <ContentArea>
                <TileContainer>
                  {tiles.map((tile, index) => (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <Tile key={index}>
                      <TileHead>
                        <TileHeading text={tile.title} />
                        <TileText text={tile.text} />
                      </TileHead>

                      <TileBody backgroundColor={tile.background}>
                        {tile.icon}
                      </TileBody>

                      <TileFoot>
                        <LinkWithArrow
                          direction="right"
                          to={`/policies/${tile.slug}`}
                          text="Read more"
                          render={props => <Link {...props} />}
                        />
                      </TileFoot>
                    </Tile>
                  ))}
                </TileContainer>
              </ContentArea>
            </BodyWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default PoliciesPage;
